.Grid {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #282c34;
}


.Grid__row {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}


.Grid__cell {
    flex-grow: 1;
    margin: 2%;
    border-radius: 10%;
}


.Grid__cell--I { background: #0ff }
.Grid__cell--J { background: #00f }
.Grid__cell--L { background: #ffa500 }
.Grid__cell--O { background: #ff0 }
.Grid__cell--S { background: #0f0 }
.Grid__cell--T { background: #a0f }
.Grid__cell--Z { background: #f00 }

.Grid__cell--I, .Grid__cell--J, .Grid__cell--L, .Grid__cell--O, .Grid__cell--S, .Grid__cell--T, .Grid__cell--Z {
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.5);
}