@import "../vars.scss";


html {
  font-size: calc(100vw / #{$gameWithOneSide_width});

  @media (min-aspect-ratio: ($gameWithOneSide_width * 100) + "/" + ($gameHeight * 100)) {
    font-size: calc(100vh / #{$gameHeight});
  }

}


.Game {
  box-sizing: border-box;
  width: $gameWithOneSide_width + rem;
  height: $gameHeight + rem;
  margin-left: auto;
  position: relative;

  @media (min-aspect-ratio: ($gameWithTwoSides_width * 100) + "/" + ($gameHeight * 100)) {
    width: $gameWithTwoSides_width + rem;
    margin-left: 0;
  }

  display: flex;
  padding: $gameMargin + rem;

  &__grid {
    width: $stackWidth + $stackBorder + rem;
    height: $stackHeight + $stackBorder + rem;
    border: solid ($stackBorder + rem) #ccc;

    .Grid__cell {
      margin: 0.1rem;
    }
  }


  &__side-left,
  &__side-right {
    width: $sideWidth + rem;
    font-size: $sideFontSize + rem;
  }
  &__side-right {
    margin-left: $sideMargin + rem;
  }
  &__side-left {
    display: none;
    margin-right: $sideMargin + rem;

    @media (min-aspect-ratio: ($gameWithTwoSides_width * 100) + "/" + ($gameHeight * 100)) {
      display: block;
    }
  }

  &__next-frame {
    margin-left: - $stackBorder + rem;
    width: $sideWidth + $stackBorder + rem;
    height: $sideWidth + $stackBorder + rem;
    display: flex;
    border: solid ($stackBorder + rem) #ccc;
    background: #282c34;
    align-items: center;
    justify-content: center;
  }

  &__next {

  }

  &__next {
    .Grid__row {
      flex-grow: 0;
    }
    .Grid__cell {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0.05rem;
    }
  }

  &__overlay {
    position: absolute;
    left: 0.4rem;
    right: 0.4rem;
    background: rgba(0,0,0, 0.7);
    border: solid 0.1rem #fff;
    text-align: center;
  }

}
