.MainMenu {

  min-width: 10rem;
  font-size: 0.9rem;
  text-align: center;

  &__title {
    margin: 0 0 4rem 0;
  }

  p {
    margin: 1rem 0;
  }

  button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
    display: inline-block;
    color: inherit;
  }

  &__starting-level {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    button {
      width: 1.3rem;
      height: 1.3rem;
      line-height: 1;
      background: #eee;
      border-radius: 50%;
      color: #000;
    }
  }



  &__start, &__exit {
    text-align: center;
  }

  &__start, &__exit {
    button {
      padding: 0.4rem 0.8rem;
      border-radius: 0.6rem;
      color: #000;
      background: #eee;
      font-size: 0.8rem;
    }
  }

}

